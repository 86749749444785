import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('queues', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`queues/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        update: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`queues/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        warehouses: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('main_warehouses', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
