<template>
    <div>
        <b-card no-body class="mb-0 mt-0">
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="6">
                        <validation-observer ref="dataForm" #default="{ invalid }">
                            <b-form ref="form" @submit.stop.prevent="handleUpdate">
                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="code" label="M/CODE">
                                            <validation-provider #default="{ errors }" name="M/CODE" rules="">
                                                <b-form-input
                                                    id="code"
                                                    name="code"
                                                    size="sm"
                                                    v-model="station.station.code"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-form-group label-for="name" label="Market Name">
                                            <validation-provider #default="{ errors }" name="Market Name" rules="">
                                                <b-form-input
                                                    id="name"
                                                    name="name"
                                                    size="sm"
                                                    v-model="station.station.name"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-form-group labe-for="sale_id" label="Sale #">
                                            <validation-provider #default="{ errors }" rules="">
                                                <b-form-select
                                                    size="sm"
                                                    id="sale_id"
                                                    name="sale_id"
                                                    v-model="sale_id"
                                                >
                                                <b-form-select-option :value="null">Select...</b-form-select-option>
                                                    <b-form-select-option
                                                        v-for="sale in station.station.sales.filter((e) => e.online)"
                                                        :key="sale.id"
                                                        :value="sale.id"
                                                    >
                                                        {{ sale.number }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="registrationNumber" label="Grower #">
                                            <validation-provider #default="{ errors }" name="Grower #" rules="">
                                                <b-form-input
                                                    id="registrationNumber"
                                                    name="registrationNumber"
                                                    size="sm"
                                                    v-model="tag.grower.registrationNumber"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="5">
                                        <b-form-group label-for="growerName" label="Grower Name">
                                            <validation-provider #default="{ errors }" name="Grower Name" rules="">
                                                <b-form-input
                                                    id="growerName"
                                                    name="growerName"
                                                    size="sm"
                                                    v-model="tag.grower.name"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="tag" label="Bale Tag">
                                            <validation-provider #default="{ errors }" name="Bale Tag" rules="">
                                                <b-form-input
                                                    ref="refTag"
                                                    id="tag"
                                                    name="tag"
                                                    size="sm"
                                                    v-model="tag.tagBarcode"
                                                    autofocus
                                                    @keyup.enter.prevent="getTag()"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.tagBarcode">{{ serverErrors.tagBarcode[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="5">
                                        <b-form-group label-for="barcode" label="Bale Ticket">
                                            <validation-provider #default="{ errors }" name="barcode" rules="">
                                                <b-form-input
                                                    id="barcode"
                                                    name="barcode"
                                                    size="sm"
                                                    v-model="tag.ticket.barcode"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="3">
                                        <b-form-group label-for="fgrade" label="House Grade">
                                            <validation-provider #default="{ errors }" name="fgrade" rules="">
                                                <b-form-input
                                                    ref="refGrade"
                                                    id="fgrade"
                                                    name="fgrade"
                                                    size="sm"
                                                    v-model="tag.fgrade"
                                                    list="fgrades"
                                                    @keyup.enter.prevent="validateGrade()"
                                                />
                                                <datalist id="fgrades">
                                                    <option
                                                        v-for="grade in grades.filter((e) => e.type == 2)"
                                                        :key="grade.id"
                                                        :value="grade.name"
                                                    />
                                                </datalist>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.fgrades">{{ serverErrors.fgrades[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="4">
                                        <b-form-group label-for="mass" label="Bought KGs">
                                            <validation-provider #default="{ errors }" name="Bought KGs" rules="">
                                                <b-form-input
                                                    id="mass"
                                                    name="mass"
                                                    size="sm"
                                                    v-model="tag.mass"
                                                    disabled
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.mass">{{ serverErrors.mass[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="3">
                                        <b-form-group label-for="reweighed" label="Reweighed KGs">
                                            <validation-provider #default="{ errors }" name="reweighed" rules="">
                                                <b-form-input
                                                    ref="refReweighed"
                                                    id="reweighed"
                                                    name="reweighed"
                                                    size="sm"
                                                    v-model="tag.reweighed"
                                                    disabled
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                                <small class="text-danger" v-if="serverErrors && serverErrors.reweighed">{{ serverErrors.reweighed[0] }}</small>
                                                <br v-if="serverErrors && serverErrors.variance" /><small class="text-danger" v-if="serverErrors && serverErrors.variance">{{ serverErrors.variance[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="2">
                                        <b-form-group label-for="variance" label="Variance">
                                            <validation-provider #default="{ errors }" name="variance" rules="">
                                                <b-form-input
                                                    id="variance"
                                                    name="variance"
                                                    size="sm"
                                                    v-model="tag.variance"
                                                    disabled
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                    <b-col>
                        <div class="d-flex justify-content-end">
                            <b-button
                                size="sm"
                                variant="outline-success"
                                @click="connectSerialPort()"
                            >
                                <span>Connect Scale</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <!-- <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Grade</span>
                            </b-button>
                        </div>
                    </b-col> -->
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive 
                :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #cell(ticket)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.barcode }}</span>
                </template>

                <template #cell(grade)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.grade }}</span>
                </template>

                <template #cell(fgrade)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.fgrade }}</span>
                </template>

                <template #cell(reweighed)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.reweighed }}</span>
                </template>

                <template #cell(variance)="data">
                    <span v-if="data.item.ticket != null">{{ data.item.ticket.reweighed - data.item.mass < 0? (data.item.ticket.reweighed - data.item.mass).toLocaleString() : '+'+(data.item.ticket.reweighed - data.item.mass).toLocaleString() }}</span>
                </template>

                <!-- Column: Actions -->
                <!-- <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template> -->
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import store from '@/store'
    import reclassificationStoreModule from '@/views/cromis/buying/reclassification/reclassificationStoreModule'
    import useReclassificationList from '@/views/cromis/buying/reclassification/useReclassificationList'

    export default {
        props: {},
        components: {
            vSelect,
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const serverErrors = ref(null)
            const saving = ref(false)
            const dataForm = ref(null)
            const refTag = ref(null)
            const refGrade = ref(null)
            const refReweighed = ref(null)
            const port = ref(null)

            const station = ref(JSON.parse(localStorage.getItem('userData')).station)

            const grades = ref([])

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filters
                sale_id,
            } = useReclassificationList()

            const tag = ref({
                id: null,
                tagBarcode: null,
                mass: null,
                reweighed: null,
                sale_id: sale_id.value,
                grower: {
                    id: null,
                    name: null,
                    registrationNumber: null,
                    number: null,
                },
                ticket: {
                    id: null,
                    barcode: null,
                    mass: null,
                },
                fgrade: null,
                fgrade_id: null,
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-reclassification'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, reclassificationStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(() => {
                store.dispatch('cromis-reclassification/grades')
                    .then(response => {
                        grades.value = response.data.grades
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })
            })

            const connectSerialPort = async () => {
                if(!('serial' in navigator)){
                    console.error('Serial port is not supported by this browser')
                    return
                }

                try{
                    // Request and open the port
                    port.value = await navigator.serial.requestPort()
                    console.log('Serial port selected')

                    await port.value.open({
                        baudRate: 9600,
                        dataBits: 7,
                        stopBits: 1,
                        flowControl: 'none',
                    })

                    // Create a TextDecoderStream to convert bytes to text
                    const decoder = new TextDecoderStream()
                    port.value.readable.pipeTo(decoder.writable)
                    const reader = decoder.readable.getReader()

                    let buffer = '';  // Buffer to accumulate incoming data

                    readSerialData(reader, buffer)
                }
                catch(error){
                    console.error('Error opening serial port', error)
                }
            }

            const readSerialData = async (reader, buffer) => {
                try{
                    while(true){
                        const { value, done } = await reader.read()

                        if(done){
                            console.log('Stream closed');
                            break;
                        }

                        if(value){
                            // Accumulate the data in the buffer
                            buffer += value;
                            console.log('Received data:', value)

                            // Look for complete lines (delimited by '\r\n')
                            let lines = buffer.split('\r\n')

                            // Process all lines except the last (incomplete)
                            for (let i = 0; i < lines.length - 1; i++) {
                                processLine(lines[i])
                            }

                            // Keep the incomplete line in the buffer
                            buffer = lines[lines.length - 1];
                        }
                    }
                }
                catch(error){
                    console.error('Error reading serial data', error)
                }
            }

            const processLine = (line) => {
                console.log('Processing line:', line);

                // Example line: 'S S 176.161 g'
                if(line.trim().length > 0 && line.includes("g")){
                    // Extract only the numeric weight value
                    const weightData = line.trim().replace(/[^0-9.]/g, '');
                    if (weightData) {
                        tag.value.reweighed = weightData;
                        console.log('Weight:', `${weightData} g`);
                    }
                }
            }

            // connectSerialPort()

            const getTag = async () => {
                await store.dispatch('cromis-reclassification/reclassify', { tagBarcode: tag.value.tagBarcode })
                            .then(response => {
                        tag.value = response.data.tag
                    })
                    .catch(error => {
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        console.log('got error', error)
                    })

                if(tag.value.id == null){
                    tag.value.tagBarcode = null
                    refTag.value.focus()
                }
                else{
                    tag.value.reweighed = 45.34
                    serverErrors.value = null
                    refGrade.value.focus()
                }
            }

            const validateGrade= async () => {
                let valid = false

                if(tag.value.fgrade == null){
                    serverErrors.value = {
                        fgrade: ['Grade is required']
                    }
                }
                else{
                    let grade = grades.value.find(grade => grade.name.toUpperCase() == tag.value.fgrade.toUpperCase() && grade.type == 2)

                    if(grade == null){
                        tag.value.fgrade = null,
                        tag.value.fgrade_id = null,
                        serverErrors.value = {
                            fgrade: ['House grade does not exist']
                        }
                    }
                    else{
                        tag.value.fgrade = grade.name
                        tag.value.fgrade_id = grade.id
                        serverErrors.value = null
                        valid = true
                    }
                }

                if(!valid){
                    tag.value.fgrade = null
                    refGrade.value.focus()
                }
                else{
                    serverErrors.value = null
                    handleUpdate(tag.value)
                }
            }

            const handleUpdate = async (item) => {
                saving.value = true

                await store.dispatch('cromis-reclassification/reclassify', tag.value)
                        .then(response => {
                            saving.value = false

                            axios.post('https://localhost:3000/run-bat').then((resp) => {
                                console.log(resp.data)
                            })

                            refTag.value.focus()

                            tag.value.tagBarcode = null
                            tag.value.mass = null
                            tag.value.fgrade = null

                            refTag.value.focus()

                            refetch()
                        })
                        .catch(error => {
                            saving.value = false
                            if(error.response.status === 422){
                                serverErrors.value = error.response.data.errors
                            }
                            else{
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    timer: 3000,
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            }
                        })
            }

            return {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                // Filters
                sale_id,

                serverErrors,
                saving,
                dataForm,
                station,
                tag,
                grades,

                connectSerialPort,
                validateGrade,
                getTag,
                handleUpdate,

                refTag,
                refGrade,
                refReweighed,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>