<template>
    <div>
        <!-- Filters -->
        <b-card no-body v-if="station == null || station.type != 3">
            <b-card-body>
                <b-row>
                    <b-col
                        cols="12"
                        md="2"
                        class="mb-md-0 mb-2"
                        v-if="station == null"
                        v-for="(toSelect, index) in selections.filter((e) => e.id > userLocation.level.id)"
                        :key="toSelect.level"
                    >
                        <label>{{ toSelect.label }}</label>
                        <b-form-select size="sm" v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="location in toSelect.locations" :key="location.id"
                                :value="location">
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col
                        cols="12"
                        md="2"
                        class="mb-md-0 mb-2"
                        v-if="station != null"
                        v-for="(toSelect, index) in selections"
                        :key="toSelect.level"
                    >
                        <label>{{ toSelect.label }}</label>
                        <b-form-select size="sm" v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="location in toSelect.locations" :key="location.id"
                                :value="location">
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <!-- <b-col cols="6" md="3">
                        <label>Destination Warehouse</label>
                        <b-form-select size="sm" v-model="warehouse" @change="changeMarket()">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse">
                                {{ warehouse.code.toUpperCase() }}: {{ warehouse.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col> -->
                </b-row>
                <!-- {{ warehouse }} -->
            </b-card-body>
        </b-card>
        <!-- ./Filters -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import queuesStoreModule from '@/views/cromis/queue/queuesStoreModule'
import useQueueList from '@/views/cromis/queue/useQueueList'
import moment from 'moment'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner,
        ValidationObserver, ValidationProvider,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const selectedRows = ref([]);
        const selectedItems = ref([]);
        const warehouses = ref([]);
        const warehouse = ref(null)

        const client = ref(JSON.parse(localStorage.getItem('userData')).client)
        const station = ref(JSON.parse(localStorage.getItem('userData')).station)
        const userLocation = ref(JSON.parse(localStorage.getItem('userData')).location)

        const CROMIS_STORE_MODULE_NAME = 'cromis-queue'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, queuesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            if(station.value != null && station.value != undefined && station.value.type == 3){
                warehouse.value = station.value.station
            }

            await store.dispatch('cromis-queue/levels')
                .then(response => {
                    levels.value = response.data.levels[0]

                    selections.value.push({
                        id: levels.value.id,
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    formSelections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    let list = levels.value.children

                    while (list.length > 0) {
                        children.value.push(list[0])
                        if (list[0].children > 0) {
                            selections.value.push({ id: list[0].id, level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                            formSelections.value.push({ id: list[0].id, level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                        }
                        else {
                            selections.value.push({ id: list[0].id, level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                            formSelections.value.push({ id: list[0].id, level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                        }

                        list = list[0].children
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })

            if(station.value == null){
                await store.dispatch('cromis-queue/locations')
                    .then(response => {
                        populateSelections(response.data.locations)
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

                await store.dispatch('cromis-queue/warehouses')
                    .then(response => {
                        warehouses.value = response.data.warehouses
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })
            }
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const changeFilters = async (location, index) => {
            // market_id.value = null
            // agreement.value.market_id = null

            // sale_id.value = null
            // agreement.value.sale_id = null

            // markets.value.splice(0)
            // sales.value.splice(0)

            if (!location) {
                for (let i = index + 1; i < selections.value.length; i++) {
                    selections.value[i].locations.splice(0)
                    selections.value[i].model = null
                }

                if (index > 0) {
                    locationFilter.value = selections.value[index - 1].model.id
                }
                else {
                    locationFilter.value = null
                }
            }
            else {
                if (selections.value[index + 1]) {
                    selections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    selections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    selections.value[index + 1].model = null
                }

                locationFilter.value = selections.value[index].model.id
            }

            agreement.value.location_id = locationFilter.value

            await store.dispatch('cromis-queue/warehouses', { location_id: locationFilter.value })
                .then(response => {
                    warehouses.value = response.data.warehouses
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeWarehouse = async () => {
            await store.dispatch('cromis-queue/queues', {  })
                .then(response => {
                    sales.value = response.data.sales
                    sales.value.forEach(ele => {
                        ele.societies.forEach(element => {
                            socitiesSale.value.push(element)
                        });
                        
                    });
                    console.log(socitiesSale.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeFormSelection = async (location, index) => {
            market_id.value = null
            agreement.value.market_id = null

            console.log(location)

            await store.dispatch('cromis-queue/warehouses', { location_id: location.id })
                .then(response => {
                    warehouses.value = response.data.warehouses
                })
                .catch(error => {
                    console.log('got error', error)
                })

            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }
            }
            else {
                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }
            }
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
            season_id,
            buyer_id,
            market_id,
            sale_id,
            society_id,
        } = useQueueList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            console.log(item)
            serverErrors.value = null

            let location = item.sale.market.address.location
            let selectedLocations = []
            while (location) {
                selectedLocations.push({ level: location.level.name.toLowerCase(), id: location.id })
                location = location.parent
            }

            for (let i = 0; i < formSelections.value.length; i++) {
                for (let n = 0; n < selectedLocations.length; n++) {
                    if (formSelections.value[i].level === selectedLocations[n].level) {
                        for (let x = 0; x < formSelections.value[i].locations.length; x++) {
                            if (formSelections.value[i].locations[x].id === selectedLocations[n].id) {
                                formSelections.value[i].model = formSelections.value[i].locations[x]

                                if ((i + 1) < formSelections.value.length) {
                                    formSelections.value[i + 1].locations = formSelections.value[i].locations[x].children
                                }
                            }
                        }
                    }
                }
            }

            agreement.value = {
                id: item.id,
                location_id: item.sale.market.address.location.id,
                season_id: item.sale.season.id,
                buyer_id: item.sale.buyer.id,
                market_id: item.sale.market.id,
                sale_id: item.sale.number,
                society_id: item.society_id,
                number: item.number,
                start: item.start,
                end: item.end,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            serverErrors.value = null

            formSelections.value.map((form) => {
                form.model = null
            })

            agreement.value = {
                id: null,
                location_id: agreement.value.location_id,
                season_id: agreement.value.season_id,
                buyer_id: agreement.value.buyer_id,
                market_id: agreement.value.market_id,
                sale_id: agreement.value.sale_id,
                society_id: agreement.value.society_id,
                number: null,
                start: null,
                end: null,
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (agreement.value.id === null || agreement.value.id === 0)
                handleCreate()
            else
                handleUpdate(agreement.value)
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-queue/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to agreement # ${response.data.number} of ${response.data.sale.market.code} sale ${response.data.sale.number} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        return {
            // Data
            client,
            selections,
            formSelections,
            levels,
            children,
            station,
            userLocation,
            warehouses,
            warehouse,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            changeFilters,
            changeWarehouse,
            changeFormSelection,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleUpdate,
            selectedRows,
            selectedItems,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';
</style>