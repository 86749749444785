import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInlineList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'number', label: 'Grower #', sortable: false },
        { key: 'grower', label: 'Grower Name', sortable: false },
        { key: 'barcode', label: 'Bale Tag', sortable: false },
        { key: 'ticket', label: 'Bale Ticket', sortable: false },
        { key: 'grade', label: 'Grade', sortable: false },
        { key: 'mass', label: 'KGs', sortable: false },
        { key: 'captured_at', sortable: false },
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('captured_at')
    const isSortDirDesc = ref(true)
    const sale_id = ref(null)

    const refetch = () => {
        records.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc, sale_id], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store
            .dispatch('cromis-inline/list', {
                sale_id: sale_id.value,
                query: searchQuery.value,
                per_page: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
            })
            .then(response => {
                const { tickets, meta } = response.data

                meta.value = meta

                callback(tickets)
                totalRecords.value = meta.value.total
                from.value = meta.value.from
                to.value = meta.value.to
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching buyers list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
        sale_id,

        refetch,
    }
}
