import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('inline_tags', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        reclassify: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('grower_tag', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        grades: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('grades', { params: params})
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    }
}
